<template>
  <div class="w3l-error-grid py-5">
        <div class="container py-lg-5 py-md-4">
            <div class="row align-items-center">
                <div class="col-md-6">
                    <h1 class="aimation-effect" style="max-width:400px;">404</h1>
                </div>
                <div class="col-md-6">
                    <h2 class="mt-4">Page not found!</h2>
                    <p class="mt-sm-4 mt-3">Sorry, we're offline right now to make our site even better. Please,
                        comeback
                        later and
                        check what we've
                        been upto.</p>
                    <form class="form-inline search-form mt-md-5 mt-4" action="#" method="post">
                        <input class="form-control" type="search" placeholder="Search here" aria-label="email"
                            required="">
                        <button class="btn btn-style search" type="submit">Search</button>
                    </form>
                    <router-link to="/" class="tn back-button mt-md-5 mt-4"> <span
                            class="fa fa-arrow-left mr-2"></span>
                        Back to Home</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


export default {
  name: '404',
  components: {
 
  }
}
</script>
